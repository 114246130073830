img {
  width: 32px;
  height: 32px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background: #EEEEEE;
  text-align: left;
  padding: 4px;
}

td {
  padding: 4px;
}

tr:nth-child(even) {
  background: #FAFAFA;
}